import { AuthorizeStep } from './../../authorizeStep';
import { ToastService } from './../../services/toast-service';
import { Router } from 'aurelia-router';
import { ErrorService } from './../../services/error-service';
import { AnalysisService } from './../../services/analysis-service';
import { autoinject, bindable, BindingEngine } from 'aurelia-framework';
import { ListField, ListSettings } from 'models/ListSettings';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Prompt } from 'elements/prompt';
import { NetShapeService } from 'services/net-shape-service';
import { DesignTypeService } from 'services/design-type-service';
import { FloaterTypeService } from 'services/floater-type-service';
import analysisListDefaultFieldSettings from './analysis-list-fields';
import { ServiceService } from 'services/service-service';
import { Models } from 'models/core';

@autoinject
export class AnalysisList {
  @bindable private analysisFilters: any;
  @bindable private context: string;
  @bindable private currentPage = 1;

  private fields: Array<ListField> = analysisListDefaultFieldSettings;
  private filters: any = {};
  private analysisFiltersQuery: any = {};
  private analysisFilterGetFiltersQuery: any;
  private pageSize = 25;
  private LS_KEY = 'ANALYSIS_LIST_SETTINGS';
  private analysisFilterClearAll: any;

  analyses: Array<Models.Analysis>;
  tableData: Array<Models.Service>;
  totalItems: number = null;
  isAnalysisEngineer = false;
  isAdministrator = false;
  analysisFilterToggleVisible: any;

  constructor(
    private router: Router,
    private errorService: ErrorService,
    private toastService: ToastService,
    private bindingEngine: BindingEngine,
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private analysisService: AnalysisService,
    private serviceService: ServiceService,
    private netShapeService: NetShapeService,
    private designTypeService: DesignTypeService,
    private floaterTypeService: FloaterTypeService
  ) {
    // For fields using services for options, get values and store them in field settings
    this.fields.forEach((field) => {
      if (field.filterOptionsService) {
        const options = this[field.filterOptionsService].getAllCached().then((res) => {
          field.filterOptions = res;
        });
      }
    });
  }

  getFilterKey() {
    return this.analysisService.ANALYSIS_FILTERS_KEY + (this.context ? '_' + this.context : '');
  }

  getFilterKeyExternal() {
    return this.analysisService.ANALYSIS_FILTERS_KEY + (this.context ? '_' + this.context : '') + '_EXTERNAL';
  }

  activate() {
    if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('AnalysisEngineer') !== -1) {
      this.isAnalysisEngineer = true;
      this.filters.signedOnly = null;
    } else if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
      this.isAdministrator = true;
      this.filters.signedOnly = null;
    } else {
      this.filters.signedOnly = true;
    }

    // get selected values from localstorage
    const listSettings: ListSettings = JSON.parse(localStorage.getItem(this.LS_KEY));

    if (listSettings) {
      this.applyListSettings(listSettings);
    }

    this.bindingEngine.propertyObserver(this.filters, 'searchText').subscribe(() => {
      void this.refreshList();
    });

    // more fields DesignTypeName, Variants (antall), NumberOfSideRopes, NumberOfLiftingRopes, NumberOfCrossRopes

    // should be a checkbox? or some kind of filtering - most user will probably only want signed analyses
    // null = all analysis, true = only signed, false = only not signed
    this.filters.signed = null;

    void this.refreshList();
  }

  /* Computed properties */
  get fieldSettings() {
    return this.fields.map((f) => {
      return {
        field: f.field,
        activeFilter: f.activeFilter,
        selected: f.selected,
      };
    });
  }
  get listSettings(): ListSettings {
    return {
      fieldSettings: this.fields,
      customFilters: {
        searchText: this.filters.searchText,
        signedOnly: this.filters.signedOnly,
      },
    };
  }
  get selectedFields() {
    return this.fields.filter((x) => x.selected);
  }
  get selectedFieldsNames() {
    return this.selectedFields.map((x) => x.field);
  }
  get activeFilterFields() {
    return this.selectedFields.filter(
      (x) => x.activeFilter && (x.activeFilter.value || x.activeFilter.fromValue || x.activeFilter.toValue)
    );
  }
  get dynamicFilters() {
    return {
      _select: this.selectedFieldsNames, // Which fields to query for
      _activeFilterFields: this.activeFilterFields, // Restrict query by these field values
      skip: this.filters.skip,
      export: this.filters._export,
      top: this.pageSize,
      searchText: this.filters.searchText,
      signed: this.filters.signedOnly,
    };
  }
  get anyFiltersActive() {
    if (this.activeFilterFields.length > 0) {
      return true;
    }
    if (this.filters.searchText) {
      return true;
    }
    if (this.filters.signedOnly) {
      return true;
    }
    return false;
  }

  /* Public methods used in view */
  public exportList() {
    const dynamicFilters = JSON.parse(JSON.stringify(this.dynamicFilters));
    dynamicFilters._export = true;

    this.analysisService
      .getList(dynamicFilters)
      .then((res) => {
        // download handled by service, ignore
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private buildQueryString(obj) {
    const arr = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        // dont include keys where value is null
        if (obj[p]) {
          arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    }
    return arr.join('&');
  }

  private sortTable(field) {
    if (!this.analysisFilters) {
      return;
    }

    const fieldName = field.orderByName ?? field;
    if (this.analysisFilters.orderBy === fieldName) {
      this.analysisFilters.orderByDirection = this.analysisFilters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.analysisFilters.orderBy = fieldName;
      this.analysisFilters.orderByDirection = 'DESC';
    }

    this.pushState();
    void this.refreshList();
  }

  private pushState() {
    const baseUrl = '#' + this.router.currentInstruction.fragment;

    const stateObj = {
      currentPage: this.currentPage,
    };

    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'analysis', baseUrl + '?' + queryString);
  }

  private refreshList() {
    try {
      setTimeout(async () => {
        this.analysisFiltersQuery = await this.analysisFilterGetFiltersQuery();
        setTimeout(() => {
          this.analysisFiltersQuery.orderBy = this.analysisFilters.orderBy;
          this.analysisFiltersQuery.orderByDirection = this.analysisFilters.orderByDirection;
          this.analysisFiltersQuery.skip = this.filters.skip;
          this.analysisFiltersQuery.top = this.pageSize;
          this.analysisFiltersQuery.searchText = this.analysisFilters.searchText;

          this.analysisService
            .getList(this.analysisFiltersQuery)
            .then((res) => {
              this.totalItems = res.headers.get('x-total-count');
              return res.text().then((responseText) => {
                this.analyses = JSON.parse(responseText);
                this.saveListSettings();
              });
            })
            .catch((err) => this.errorService.handleError(err));
        });
      });
    } catch (e) {
      this.errorService.handleError(e);
    }
  }

  public newAnalysis() {
    void this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'analysis.ConfirmCreateHeader',
          message: 'analysis.ConfirmCreateText',
          actions: {
            continue: { enabled: true, t: 'analysis.new' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.analysisService
            .createAnalysis({})
            .then((res) => {
              this.toastService.showSuccess('analysis.created');

              this.openAnalysis(res);
            })
            .catch((err) => this.errorService.handleError(err));
        }
      });
  }

  public openAnalysis(analysis: Models.Analysis) {
    this.router.navigateToRoute('analysis-details', { Id: analysis.Id });
  }

  async clearFilters() {
    await this.analysisFilterClearAll();
  }

  public currentPageChanged(newValue, oldValue) {
    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }
    this.filters.skip = (newValue - 1) * this.analysisFiltersQuery.top;
    void this.refreshList();
  }
  public closeDropdown() {
    void this.refreshList();
    this.eventAggregator.publish('dropdownClose', 1);
  }

  /* Private methods for use in this class only */
  private applyListSettings(listSettings) {
    // Apply searchText
    if (listSettings.customFilters?.searchText) {
      this.filters.searchText = listSettings.customFilters.searchText;
    }
    if (listSettings.fieldSettings) {
      listSettings.fieldSettings.forEach((field) => {
        // Apply existing field filters
        this.fields.find((f) => f.field == field.field).activeFilter = field.activeFilter;
        // Apply user selected columns
        this.fields.find((f) => f.field == field.field).selected = field.selected;
      });
    }
  }
  private saveListSettings() {
    localStorage.setItem(this.LS_KEY, JSON.stringify(this.listSettings));
  }
}
