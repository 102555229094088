import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { LoopService } from 'services/loop-service';
import { NetService } from 'services/net-service';
import { AnalysisNettingService } from 'services/analysis-netting-service';
import { ToastService } from 'services/toast-service';
import { AnalysisService } from 'services/analysis-service';
import { NetMaskTypeService } from 'services/netmask-type-service';
import { Role } from 'models/UserModels';

@autoinject
export class FormNetting {
  private analysisId: number;
  private nettings: Array<Models.AnalysisNetting>;
  private nettingNewFormVisible: boolean = false;
  private nettingEditFormVisible: number = null;
  private minimumBreakingStrength: any;
  private nextTabIndex: number = null;
  private locked: boolean = false;
  private validationMessages: Array<string>;
  private analysis: Models.Analysis;

  private subscription1: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private loopService: LoopService,
    private netService: NetService,
    private analysisService: AnalysisService,
    private analysisNettingService: AnalysisNettingService,
    private netMaskTypeService: NetMaskTypeService,
    private router: Router,
    private toastService: ToastService
  ) { }

  private activate(params) {
    this.analysisId = parseInt(params.Id);
    this.getNettings(this.analysisId);
    //this.getLoops(this.netId);

    // Update nettings array when creating new nettings
    this.subscription1 = this.eventAggregator.subscribe('nettingListReset', (value) => {
      this.nettingEditFormVisible = null;
      this.getNettings(this.analysisId);
    });

    this.subscription3 = this.eventAggregator.subscribe('nettingFormEditClose', (value) => {
      return this.getNettings(this.analysisId);
    });

    this.eventAggregator.subscribe('nettingFormNewClose', (value) => {
      this.nettingNewFormVisible = false;
    });

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private deactivate() {
    if (this.subscription1) {
      this.subscription1.dispose();
    }
    if (this.subscription2) {
      this.subscription2.dispose();
    }
    if (this.subscription3) {
      this.subscription3.dispose();
    }
    if (this.subscription4) {
      this.subscription4.dispose();
    }
  }

  private canDeactivate() {
    if (this.nettingNewFormVisible || this.nettingEditFormVisible > 0) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.subFormOpenHeading',
            message: 'dialog.subFormOpenMessage',
            actions: {
              delete: { enabled: false },
              save: { enabled: false },
              cancel: { enabled: true, t: 'dialog.cancel' },
              dontsave: { enabled: false },
              continue: { enabled: true, t: 'dialog.continue' },
            },
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'continue') {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private getAnalysis() {
    this.analysisService
      .get(this.analysisId)
      .then((res) => {
        this.analysis = res;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNettings(analysisId) {
    this.analysisNettingService
      .getAll(
        '?$expand=Placement,Material,MeshSize,NetMaskType,NettingType,ThreadType&$filter=AnalysisId eq ' + analysisId
      )
      .then((res) => {
        this.nettings = res;
        this.getAnalysis();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteNetting(id) {
    this.analysisNettingService
      .delete(id)
      .then((res) => {
        this.toastService.showSuccess('netting.deleted');
        this.getNettings(this.analysisId);
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
