import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AnalysisService } from 'services/analysis-service';
import { ErrorService } from 'services/error-service';
import { LinkedNetDto } from 'models/Analysis';

@autoinject
export class FormNets {
  private analysisId: number;
  private nextTabIndex: number = null;
  protected nets: Array<LinkedNetDto>;

  constructor(
    private analysisService: AnalysisService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router
  ) {}

  protected activate(params: { Id: string }) {
    this.analysisId = parseInt(params.Id);
    void this.getLinkedNets();

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private async getLinkedNets() {
    try {
      const nets = await this.analysisService.getNetsLinkedToAnalysis(this.analysisId);
      this.nets = nets;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected openNet(net: LinkedNetDto) {
    this.router.navigate('/net-detail/' + net.NetId);
  }

  protected canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
    return true;
  }
}
