import { ListField } from 'models/ListSettings';

const analysisListDefaultFieldSettings: Array<ListField> = [
  {
    field: 'Id',
    title: 'analysis.Id',
    selected: true,
    visible: false,
    filterType: 'TEXT',
    transform: (value: string) => value,
  },
  {
    field: 'ReportReference',
    title: 'analysis.ReportReference',
    selected: true,
    visible: true,
    filterType: 'TEXT',
  },
  {
    field: 'Comment',
    title: 'analysis.Comment',
    selected: true,
    visible: true,
    filterType: null,
  },
  {
    field: 'SignedDate',
    title: 'analysis.SignedDate',
    selected: true,
    visible: true,
    filterType: 'DATE_RANGE',
  },
  {
    field: 'SignedBy',
    title: 'analysis.SignedBy',
    selected: false,
    visible: true,
    filterType: 'USER_SELECT',
  },
  {
    field: 'CircumferenceProduction',
    title: 'analysis.CircumferenceProduction',
    selected: true,
    visible: true,
    filterType: 'NUMERIC',
    filterQueryStringKey: 'circumference',
  },
  {
    field: 'NetShapeName',
    title: 'notlogg.netshape',
    selected: true,
    visible: true,
    filterType: 'MULTI_SELECT',
    filterQueryStringKey: 'netShapeIds',
    filterOptionsService: 'netShapeService',
    filterOptions: [],
  },
  {
    field: 'FloaterTypeName',
    title: 'notlogg.floaterType',
    selected: true,
    visible: true,
    filterType: 'MULTI_SELECT',
    filterQueryStringKey: 'floaterTypeIds',
    filterOptionsService: 'floaterTypeService',
    filterOptions: [],
  },
  {
    field: 'DesignTypeName',
    title: 'notlogg.designtype',
    selected: true,
    visible: true,
    filterType: 'MULTI_SELECT',
    filterQueryStringKey: 'designTypeIds',
    filterOptionsService: 'designTypeService',
    filterOptions: [],
  },
  {
    field: 'DepthBottom',
    title: 'analysis.DepthBottom',
    selected: true,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'DepthBottomCenter',
    title: 'analysis.DepthBottomCenter',
    selected: true,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'DepthBottomCenterExtraBottom',
    title: 'analysis.DepthBottomCenterExtraBottom',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'NumberOfSides',
    title: 'notlogg.NumberOfSides',
    selected: true,
    visible: true,
    filterType: 'NUMERIC',
    filterQueryStringKey: 'sides',
  },
  {
    field: 'NumberOfSideRopes',
    title: 'notlogg.NumberOfSideRopes',
    selected: true,
    visible: true,
    filterType: 'NUMERIC',
    activeFilter: null,
  },
  {
    field: 'NumberOfLiftingRopes',
    title: 'notlogg.NumberOfLiftingRopes',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
    activeFilter: null,
  },
  {
    field: 'NumberOfCrossRopes',
    title: 'notlogg.NumberOfCrossRopes',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'Variants',
    title: 'analysis.Variant_plural',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'MinHs',
    title: 'analysis.MinHs',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
    filterQueryStringKey: 'hs',
  },
  {
    field: 'MaxHs',
    title: 'analysis.MaxHs',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'MinVs',
    title: 'analysis.MinVs',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
    filterQueryStringKey: 'vs',
  },
  {
    field: 'MaxVs',
    title: 'analysis.MaxVs',
    selected: false,
    visible: true,
    filterType: 'NUMERIC',
  },
  {
    field: 'IsCombiNet',
    title: 'general.combiNet',
    selected: false,
    visible: true,
    filterType: 'TEXT',
    transform: (value: boolean) => (value ? 'general.yes' : 'general.no'),
  },
];
export default analysisListDefaultFieldSettings;
