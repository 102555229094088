import { autoinject, bindable } from 'aurelia-framework';

export type InvoiceStatus = 'invoiced' | 'partial' | 'notInvoiced' | undefined;

@autoinject
export class InvoiceBadge {
  @bindable protected type: 'invoiced' | 'partial' | 'notInvoiced' | undefined = undefined;
  @bindable protected invoicedDate: Date;

  public static GetInvoiceType(type?: boolean[]): InvoiceStatus {
    if (type === undefined || type.length < 1) return undefined;

    const invoiced = type.every((x) => x === true);
    const notInvoiced = type.every((x) => x === false);

    if (invoiced) {
      return 'invoiced';
    } else if (notInvoiced) {
      return 'notInvoiced';
    } else {
      return 'partial';
    }
  }

  constructor() {}
}
