import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { Role } from 'models/UserModels';
import { AnalysisService } from 'services/analysis-service';
import { AnalysisVariantService } from 'services/analysis-variant-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { AnalysisVariantDialog } from '../analysis-variant-dialog/analysis-variant-dialog';

@autoinject
export class FormVariants {
  private analysisId: number;
  private variants: Array<Models.AnalysisVariant>;
  private nextTabIndex: number = null;
  private openVariantAsDialog: boolean = true;
  private lastClickedVariantId: number = null;
  private analysis: Models.Analysis;
  private locked: boolean = false;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private analysisService: AnalysisService,
    private analysisVariantService: AnalysisVariantService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService
  ) { }

  private activate(params) {
    this.analysisId = parseInt(params.Id);
    this.getVariants();

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
    return true;
  }

  private getAnalysis() {
    this.analysisService
      .get(this.analysisId)
      .then((res) => {
        this.analysis = res;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getVariants() {
    this.analysisVariantService
      .getAll(
        '?$expand=AnalysisDeadFishSystem,AnalysisSpagetthiSinker,AnalysisSingleSinker,AnalysisRingSinker&$filter=AnalysisId eq ' +
        this.analysisId
      )
      .then((res) => {
        this.variants = res;
        this.getAnalysis();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private showVariantDetails(id, analysisId) {
    this.lastClickedVariantId = id;

    document.querySelector('html').style.overflowY = 'hidden';
    this.dialogService
      .open({
        viewModel: AnalysisVariantDialog,
        model: { Id: id, AnalysisId: analysisId },
        lock: false,
        position: (mc, mo) => { },
      })
      .whenClosed((x) => {
        document.querySelector('html').style.overflowY = null;
        this.getVariants();
      })
      .catch((e) => {
        document.querySelector('html').style.overflowY = null;
      });
  }

  createNewVariant() {
    this.analysisVariantService
      .createVariant(this.analysisId)
      .then((res) => {
        this.getVariants();
        this.showVariantDetails(res.Id, res.AnalysisId);
      })
      .catch((e) => {
        document.querySelector('html').style.overflowY = null;
      });
  }

  deleteVariant(id, analysisId, event) {
    this.analysisVariantService.delete(id).then((res) => {
      this.getVariants();
      this.toastService.showSuccess('analysisVariant.deleted');
    });
  }
}
