import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { ErrorService } from './../../services/error-service';
import { AnalysisService } from 'services/analysis-service';
import { ToastService } from 'services/toast-service';
import { Role } from 'models/UserModels';
import { Models } from 'models/core';
import { ApplicationState } from 'lib/state';
import { connectTo } from 'aurelia-store';

@autoinject
@connectTo()
export class FormSigning {
  private analysisId: number;
  private analysis: Models.Analysis;
  private nextTabIndex: number = null;

  state: ApplicationState;
  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  isAnalysisEngineer = false;
  todaysDate = new Date();
  locked = false;

  constructor(
    private analysisService: AnalysisService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private router: Router
  ) {}

  activate(params: { Id: number }) {
    this.analysisId = params.Id;
    this.getAnalysis();

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private getAnalysis() {
    this.analysisService
      .get(this.analysisId)
      .then((res) => {
        this.analysis = res;
        this.locked = res.Locked ? res.Locked : false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public signAnalysis() {
    void this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'analysis.ConfirmSignHeader',
          message: 'analysis.ConfirmSignText',
          actions: {
            continue: { enabled: true, t: 'analysis.Sign' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.analysisService
            .signAnalysis(this.analysis)
            .then(() => {
              this.toastService.showSuccess('analysis.signed');
              this.getAnalysis();
            })
            .catch((err) => this.errorService.handleError(err));
        }
      });
  }

  private canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
    return true;
  }
}
