import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from './../../services/error-service';
import { AnalysisService } from 'services/analysis-service';
import { AnalysisDesignService } from 'services/analysis-design-service';
import { FloaterTypeService } from 'services/floater-type-service';
import { DesignTypeService } from 'services/design-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { Role } from 'models/UserModels';

@autoinject
export class FormMain {
  private analysisId: number;
  private analysisDesign: Models.AnalysisDesign;
  private nextTabIndex: number = null;
  private originalObject: Models.AnalysisDesign;
  private netDesigns: Models.DesignType[];

  analysis: Models.Analysis;
  isAnalysisEngineer = false;
  locked = false;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private analysisService: AnalysisService,
    private analysisDesignService: AnalysisDesignService,
    private designTypeService: DesignTypeService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private utility: Utility,
    private toastService: ToastService,
    private router: Router,
    private floaterTypeService: FloaterTypeService
  ) {}

  async activate(params: { Id: number }) {
    this.analysisId = params.Id;

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    await this.getNetDesigns();
    await this.getAnalysisDesign();
  }

  async getNetDesigns() {
    const designs = await this.designTypeService.getAllCached();
    this.netDesigns = designs
      .filter((design) => !design.IsDeleted)
      .map((design) => ({
        ...design,
        Name: `${design.DesignCode} (${design.Name})`,
        NameEn: `${design.DesignCode} (${design.NameEn})`,
        NameEs: `${design.DesignCode} (${design.NameEs})`,
      }));

    return this.netDesigns;
  }

  async getAnalysisDesign() {
    try {
      const analysisDesign = await this.analysisDesignService.get(this.analysisId);
      await this.getAnalysis();
      this.analysisDesign = analysisDesign;
      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  updateTotalDepth() {
    if (this.analysisDesign.DepthBottom) {
      const bottomPlussVertical =
        Number.parseFloat(`${this.analysisDesign.DepthBottom}`) +
        (Number.parseFloat(`${this.analysisDesign.DepthBottomCenter}`) || 0);

      const bottomPlussOuter =
        Number.parseFloat(`${this.analysisDesign.DepthBottom}`) +
        (Number.parseFloat(`${this.analysisDesign.DepthOuterBottom}`) || 0);

      this.analysisDesign.TotalDepth = Math.max(bottomPlussVertical, bottomPlussOuter);
    } else {
      this.analysisDesign.TotalDepth = null;
    }
  }

  updateNumberOfSideRopes() {
    this.analysisDesign.NumberOfSideRopes =
      Number.parseFloat(`${this.analysisDesign.FloaterNetConnections || 0}`) -
      Number.parseFloat(`${this.analysisDesign.NumberOfLiftingRopes || 0}`);
  }

  async getAnalysis() {
    try {
      const analysis = await this.analysisService.get(this.analysisId);
      this.analysis = analysis;
      this.locked = analysis.Locked ? analysis.Locked : false;
      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async updateAnalysisDesign() {
    /*     this.analysisDesign.NetShape = null;
    this.analysisDesign.DesignType = null;
    this.analysisDesign.FloaterType = null; */
    try {
      this.analysisDesign.NetShapeId = this.netDesigns.find(
        (nd) => nd.Id == this.analysisDesign.DesignTypeId
      )?.DefaultNetShapeId;

      await this.analysisDesignService.put(this.analysisDesign, this.analysisDesign.Id);
      this.updateOriginalObject();
      this.eventAggregator.publish('analysis:updated');
      this.toastService.showSuccess('analysisdesign.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private updateOriginalObject() {
    if (!this.analysisDesign) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.analysisDesign));
  }

  canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.analysisDesign, this.originalObject)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed(async (response) => {
          if (response.wasCancelled) {
            return false;
          }
          const result = response.output;

          if (result !== 'save') {
            this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
            return true;
          }

          // Save the service and let that function handle the rest of the logic
          await this.updateAnalysisDesign();
          return false;
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }
}
