import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Prompt } from 'elements/prompt';
import { HistoryDialog } from './../history-dialog/history-dialog';
import { DialogService } from 'aurelia-dialog';
import { AuthorizeStep } from './../../authorizeStep';
import { ToastService } from './../../services/toast-service';
import { Router, RouterConfiguration } from 'aurelia-router';
import { ErrorService } from './../../services/error-service';
import { AnalysisService } from './../../services/analysis-service';
import { AnalysisDesignService } from './../../services/analysis-design-service';
import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { Models } from 'models/core';
import { Role } from 'models/UserModels';
import { connectTo } from 'aurelia-store';
import { isInRole } from 'lib/user/userHelper';
import { ApplicationState } from 'lib/state';

@autoinject
@connectTo()
export class AnalysisDetails {
  private analysisId: number;
  private analysis: Models.Analysis;
  private subscription: Subscription;

  public analysisDesign: Models.AnalysisDesign;
  public isAnalysisEngineer = false;

  private state: ApplicationState;
  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];
  notAllowedToUpdate = false;

  constructor(
    private analysisService: AnalysisService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private deleteDialogService: DeleteDialogService,
    private eventAggregator: EventAggregator,
    private analysisDesignService: AnalysisDesignService,
    private router: Router
  ) {}

  attached() {
    this.notAllowedToUpdate = !isInRole(this.state.roles, this.rolesAllowedToUpdate);
  }

  private configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: [''],
        name: 'analysis-detail-main',
        moduleId: PLATFORM.moduleName('./form-main'),
        tabindex: 0,
        entityShortName: 'analysis',
      },
      {
        route: ['design'],
        name: 'analysis-detail-design',
        moduleId: PLATFORM.moduleName('./form-design'),
        tabindex: 1,
        entityShortName: 'analysis',
      },
      {
        route: ['rope'],
        name: 'analysis-detail-rope',
        moduleId: PLATFORM.moduleName('./form-rope'),
        tabindex: 2,
        entityShortName: 'analysis',
      },
      {
        route: ['netting'],
        name: 'analysis-detail-netting',
        moduleId: PLATFORM.moduleName('./form-netting'),
        tabindex: 3,
        entityShortName: 'analysis',
      },
      {
        route: ['loop'],
        name: 'analysis-detail-loop',
        moduleId: PLATFORM.moduleName('./form-loop'),
        tabindex: 4,
        entityShortName: 'analysis',
      },
      {
        route: ['variant'],
        name: 'analysis-detail-variant',
        moduleId: PLATFORM.moduleName('./form-variant'),
        tabindex: 5,
        entityShortName: 'analysis',
      },
      {
        route: ['signing'],
        name: 'analysis-detail-signing',
        moduleId: PLATFORM.moduleName('./form-signing'),
        tabindex: 6,
        entityShortName: 'analysis',
      },
      {
        route: ['nets'],
        name: 'analysis-detail-nets',
        moduleId: PLATFORM.moduleName('./form-nets'),
        tabindex: 7,
        entityShortName: 'analysis',
      },
    ]);
  }

  activate(params: { Id: number }) {
    if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('AnalysisEngineer') !== -1) {
      this.isAnalysisEngineer = true;
    }

    this.analysisId = params.Id;
    void this.getAnalysis();

    this.subscription = this.eventAggregator.subscribe('analysis:updated', () => this.getAnalysis());

    // this.isAllowedToUpdate = isInRole(this?.state?.roles, this.rolesAllowedToUpdate);
  }

  deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private async getAnalysis() {
    try {
      const analysis = await this.analysisService.get(this.analysisId);
      this.analysis = analysis;
      await this.getAnalysisDesign(analysis.AnalysisDesignId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getAnalysisDesign(id: number) {
    try {
      const analysisDesign = await this.analysisDesignService.get(id + '?$expand=NetShape');
      this.analysisDesign = analysisDesign;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async showHistory() {
    const viewModel = {
      EntityType: 'Analysis',
      EntityId: this.analysis.Id,
    };

    await this.dialogService.open({
      viewModel: HistoryDialog,
      model: viewModel,
      lock: false,
      position: () => 0,
    });
  }

  public async unlockAnalysis() {
    const usage = <any>await this.analysisService.getAnalysisUsage(this.analysisId);
    if (usage.UsedInNets > 0) {
      this.toastService.showError('analysis.cantUnlockAlreadyUsedInNets');
      return;
    }

    void this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'analysis.confirmUnlockHeader',
          message: 'analysis.confirmUnlockText',
          actions: {
            continue: { enabled: true, t: 'analysis.unlock' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed(async (res) => {
        if (!res.wasCancelled) {
          try {
            await this.analysisService.unlockAnalysis(this.analysisId);
            await this.getAnalysis();
            this.toastService.showSuccess('analysis.unlockedAnalysis');
          } catch (error) {
            this.errorService.handleError(error);
          }
        }
      });
  }

  public async deleteAnalysis() {
    const usage = <any>await this.analysisService.getAnalysisUsage(this.analysisId);
    if (usage.UsedInNets > 0) {
      this.toastService.showError('analysis.cantDeleteAlreadyUsedInNets');
      return;
    }

    void this.deleteDialogService.confirmBeforeDelete(async () => {
      try {
        await this.analysisService.delete(this.analysisId);
        this.toastService.showSuccess('analysis.deleted');
        this.router.navigateToRoute('analysis-list');
      } catch (error) {
        this.errorService.handleError(error);
      }
    });
  }
}
