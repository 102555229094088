import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from './../../services/error-service';
import { AnalysisService } from 'services/analysis-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';
import { NetStandardService } from 'services/net-standard-service';
import { Role } from 'models/UserModels';
import { connectTo } from 'aurelia-store';

@autoinject
@connectTo()
export class FormMain {
  private analysisId: number;
  private analysis: Models.Analysis;
  private nextTabIndex: number = null;
  private originalObject: Models.Analysis;

  public isAnalysisEngineer = false;
  public locked = false;

  rolesAllowedToUpdate: Role[] = ['AnalysisEngineer'];

  constructor(
    private analysisService: AnalysisService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private utility: Utility,
    private toastService: ToastService,
    private router: Router,
    private netStandardService: NetStandardService
  ) { }

  activate(params: { Id: number }) {
    this.analysisId = params.Id;

    void this.getAnalysis();

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  public setNetStandard(id: number) {
    this.analysis.NetStandardId = id;
  }

  public async getAnalysis() {
    try {
      const analysis = await this.analysisService.get(this.analysisId);
      this.analysis = analysis;
      this.locked = analysis.Locked ? analysis.Locked : false;
      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async updateAnalysis() {
    try {
      await this.analysisService.put(this.analysis, this.analysis.Id);
      this.eventAggregator.publish('analysis:updated');
      this.updateOriginalObject();
      this.toastService.showSuccess('analysis.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private updateOriginalObject() {
    if (!this.analysis) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.analysis));
  }

  public canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.analysis, this.originalObject)) {
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // Save the service and let that function handle the rest of the logic
              void this.updateAnalysis();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);

              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }
}
